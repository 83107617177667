import * as React from "react";
import usePrevious from "./usePrevious";

export interface ModelState {
    message: string;
}

export type ModelStateDictionary = { [key: string]: ModelState };

const networkError: ModelState = {
    message: "Check internet connection, we can reach Oplane right now"
};

const unauthorized: ModelState = {
    message: "You are not unauthorized to do that."
};

const serverError: ModelState = {
    message: "Oops, we're having some trouble right now. Try again."
};

interface State<T> {
    state: "IDLE" | "PENDING" | "ERROR";
    errors?: ModelStateDictionary;
    response?: T;
}

export default function <TResult>(
    baseRequest: Partial<RequestInit & { url?: string }>,
    onSuccess?: (data: TResult) => void
) {
    const [request, setRequest] = React.useState<RequestInit & { url?: string }>(null);
    const previous = usePrevious(request);

    const [state, setState] = React.useState<State<TResult>>({ state: "IDLE" });

    React.useEffect(() => {
        if (request == null || previous === request) {
            return undefined;
        }

        setState({ state: "PENDING" });
        const controller = new AbortController();

        const signals = [controller.signal];

        if (process.env.NODE_ENV === "production") {
            signals.push(AbortSignal.timeout(20 * 1000));
        }

        const doFetch = async () => {
            let response;

            try {
                if (!request.url) {
                    return;
                }

                const { url, ...options } = request;

                response = await fetch(url, {
                    ...options,
                    signal: AbortSignal.any(signals)
                });
            } catch (err) {
                if (err.name === "AbortError") {
                    console.log("Fetch cancelled");
                    setState({ state: "IDLE" });
                }
                setState({
                    state: "ERROR",
                    errors: { "": networkError }
                });
                return;
            }

            if (response.status === 401) {
                setState({
                    state: "ERROR",
                    errors: { "": unauthorized }
                });
                return;
            }

            if (response.status === 500) {
                setState({
                    state: "ERROR",
                    errors: { "": serverError }
                });
                return;
            }

            if (response.type === "opaqueredirect") {
                setState({ state: "IDLE" });
                return;
            }

            if (
                response.headers.has("Content-Type") &&
                response.headers.get("Content-Type").indexOf("application/json") >= 0
            ) {
                try {
                    const data = await response.json();

                    if (response.status === 400) {
                        setState({
                            state: "ERROR",
                            errors: data as ModelStateDictionary
                        });
                        return;
                    } else if (response.ok) {
                        if (onSuccess) {
                            onSuccess(data);
                        }

                        setState({ state: "IDLE", response: data as TResult });
                        return;
                    }
                } catch (e) {
                    // fall trought to error
                    console.error("Response was not valid JSON", e);
                }
            }

            setState({
                state: "ERROR",
                errors: { "": serverError }
            });
        };

        doFetch();
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request, onSuccess]);

    return {
        sendRequest: (request: Partial<RequestInit & { url?: string }>) =>
            setRequest({ ...baseRequest, ...request }),
        ...state
    };
}
