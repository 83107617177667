import * as React from "react";
import * as styles from "./Link.css";

interface LinkProps extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "className"> {
    children?: React.ReactNode;
}

const Link = ({ children, ...linkProps }: LinkProps) => {
    return (
        <a className={styles.link} {...linkProps}>
            {children}
        </a>
    );
};

export default Link;
