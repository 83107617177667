import useFetch from "./useFetch";

export interface IdpResult {
    action: "NO_ACTION" | "REDIRECT_TO_IDP";
    redirectUrl: string;
}

export default (returnUrl: string) => {
    const { sendRequest, state, response } = useFetch<IdpResult>({});
    return {
        commit: (email: string) => {
            sendRequest({
                url: `/federation/check?email=${encodeURIComponent(email)}&returnUrl=${encodeURIComponent(returnUrl)}`,
                method: "POST",
                redirect: "manual",
                credentials: "same-origin"
            });
        },
        state,
        response
    };
};
