import Button from "@ds/elements/inputs/buttons/Button";
import Label from "@ds/elements/text/Label";
import EmailIcon from "@ds/icons/monochrome/24px/alternate-email.svg";
import EditIcon from "@ds/icons/monochrome/24px/edit-filled.svg";
import PasswordIcon from "@ds/icons/monochrome/24px/password.svg";
import classnames from "classnames";
import TextInput from "design-system/src/elements/inputs/TextInput/TextInput";
import * as React from "react";
import { useForm } from "react-hook-form";
import Link from "../../../Shared/Link";
import Stack from "../../../Shared/Stack";
import * as styles from "./AuthenticationForm.css";
import PageBox from "./PageBox";
import useAuthenticate from "./useAuthenticate";
import useCheckFederation from "./useCheckFederation";

export interface AuthenticationFormProps {
    requestPasswordRecoveryUrl: string;
    returnUrl: string;
    xsrf: string;
}

const AuthenticationForm = (props: AuthenticationFormProps) => {
    const [pwEnabled, setPwEnabled] = React.useState(false);
    const {
        commit: checkFederation,
        state: checkFederationState,
        response: federationResult
    } = useCheckFederation(props.returnUrl);

    const {
        commit: unpwCommit,
        state: authenticationState,
        errors: authErrors
    } = useAuthenticate(props.returnUrl, props.xsrf);

    const { register, handleSubmit, formState, setFocus } = useForm<{
        email: string;
        password: string;
    }>({
        mode: "onTouched",
        errors: authenticationState === "ERROR" && authErrors
    });

    const handleEdit = React.useCallback(() => {
        setPwEnabled(false);
        setFocus("email");
    }, [setFocus, setPwEnabled]);

    const submit = handleSubmit(inputs => {
        if (!pwEnabled) {
            checkFederation(inputs.email);
        } else if (pwEnabled) {
            unpwCommit(inputs.email, inputs.password);
        }
    });

    React.useEffect(() => {
        if (federationResult) {
            if (federationResult.action === "NO_ACTION") {
                setPwEnabled(true);
                setFocus("password");
            } else if (federationResult.action === "REDIRECT_TO_IDP") {
                window.location.href = federationResult.redirectUrl;
            }
        }
    }, [federationResult, setFocus]);

    const footer = (
        <div className={styles.disclaimer}>
            <Link href="https://www.oplane.io/privacy-policy.html">
                <Label scale="large" color="on-tertiary-container">
                    Privacy policy
                </Label>
            </Link>
            <Link href="mailto:support@oplane.io">
                <Label scale="large" color="on-tertiary-container">
                    Get help
                </Label>
            </Link>
            <Link href="https://www.oplane.io">
                <Label scale="large" color="on-tertiary-container">
                    Oplane
                </Label>
            </Link>
        </div>
    );

    const containerStyles = classnames({
        [styles.container]: true,
        [styles.password__enabled]: pwEnabled
    });

    return (
        <PageBox title="Sign in" footer={footer}>
            <div className={containerStyles}>
                <form>
                    <Stack space={3}>
                        <TextInput
                            {...register("email", {
                                required: "Provide a email address",
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Entered value does not match email format"
                                }
                            })}
                            readOnly={pwEnabled}
                            placeholder="Email address"
                            leadIcon={<EmailIcon />}
                            trailIcon={
                                pwEnabled && (
                                    <div className={styles.editEmail} onClick={handleEdit}>
                                        <EditIcon />
                                    </div>
                                )
                            }
                            error={!!formState.errors.email}
                            supportiveText={formState.errors.email?.message}
                        />

                        <div className={styles.passwordContainer}>
                            <TextInput
                                type="password"
                                leadIcon={<PasswordIcon />}
                                {...register("password", { required: pwEnabled })}
                                error={
                                    !!formState.errors.password || (authErrors && !!authErrors[""])
                                }
                                supportiveText={
                                    formState.errors.password?.message ||
                                    (authErrors && authErrors[""] && authErrors[""].message)
                                }
                            />
                        </div>
                    </Stack>

                    <div className={styles.signIn}>
                        <Button
                            variant="filled"
                            color="tertiary"
                            type="submit"
                            busy={
                                checkFederationState === "PENDING" ||
                                authenticationState === "PENDING"
                            }
                            onClick={submit}
                            label={pwEnabled ? "Sign in" : "Continue"}
                        />

                        {pwEnabled && (
                            <>
                                <div className={styles.forgotPw}>
                                    <Link href={props.requestPasswordRecoveryUrl}>
                                        <Label scale="large" color="on-tertiary-container">
                                            Forgot password?
                                        </Label>
                                    </Link>
                                </div>
                            </>
                        )}
                    </div>
                </form>
            </div>
        </PageBox>
    );
};

export default AuthenticationForm;
