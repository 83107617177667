import * as React from "react";
import { createRoot } from "react-dom/client";
import OplaneLogo from "./oplane-logo-white.svg";
import AuthenticationForm from "./AuthenticationForm";
import * as styles from "./LoginPage.css";

interface Props {
    returnUrl: string;
    requestPasswordRecoverUrl: string;
    xsrf: string;
}

const LoginPage = ({ xsrf, returnUrl, requestPasswordRecoverUrl }: Props) => {
    return (
        <div className={styles.container}>
            <img src={OplaneLogo} className={styles.logo} />

            <div className={styles.loginBox}>
                <AuthenticationForm
                    requestPasswordRecoveryUrl={requestPasswordRecoverUrl}
                    returnUrl={returnUrl}
                    xsrf={xsrf}
                />
            </div>
        </div>
    );
};

const htmlEl = document.documentElement;
const domNode = document.getElementById("root");
const root = createRoot(domNode);

root.render(
    <LoginPage
        returnUrl={htmlEl.dataset.returnUrl}
        requestPasswordRecoverUrl={htmlEl.dataset.passwordRecoveryUrl}
        xsrf={htmlEl.dataset.xsrf}
    />
);
