import classNames from "classnames";
import React from "react";
import * as styles from "./Label.css";

interface LabelProps {
    scale?: "small" | "medium" | "large";
    children?: React.ReactNode;
    prominent?: boolean;
    color?:
        | "on-primary"
        | "on-primary-container"
        | "on-secondary"
        | "on-secondary-container"
        | "on-tertiary"
        | "on-tertiary-container"
        | "on-background"
        | "on-error"
        | "on-error-container"
        | "on-surface"
        | "on-surface-variant"
        | "inverse-on-surface";
}

const Label = ({ children, prominent = false, scale, color }: LabelProps) => {
    return (
        <span
            className={classNames(
                styles.label,
                styles[scale || "medium"],
                styles[color || "on-surface"],
                {
                    [styles.prominent]: prominent
                }
            )}
        >
            {children}
        </span>
    );
};

export default Label;
