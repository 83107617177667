import classnames from "classnames";
import * as React from "react";
import * as styles from "./Button.css";

type MouseEventCallback = (e: React.MouseEvent<HTMLElement>) => void;

export interface ButtonProps
    extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "disabled"> {
    label?: string | React.ReactNode;
    icon?: React.ReactNode;

    target?: string; // html property
    type?: "button" | "submit" | "reset";

    variant?: "filled" | "text" | "tonal";
    color?: "primary" | "secondary" | "tertiary" | "danger";
    onClick?: (() => void) | MouseEventCallback;

    // states
    disabled?: boolean;
    busy?: boolean;

    children?: React.ReactNode;
    tabIndex?: number;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            icon,
            color = "primary",
            label,
            variant = "text",
            children,
            disabled,
            busy,
            type,
            ...others
        },
        ref
    ) => {
        const classes = classnames(styles.container, `${styles[variant]}`, `${styles[color]}`, {
            [styles.disabled]: disabled,
            [styles.withIcon]: !!icon
        });

        const buttonProps = {
            ...others,
            ref,
            className: classes
        };

        if (busy || disabled) {
            buttonProps.onClick = null;
        }

        const content = (
            <>
                {icon && <span className={styles.icon}>{icon}</span>}
                <span>{label || children}</span>
            </>
        );

        return (
            <button {...buttonProps} role="button" type={type || "button"} disabled={disabled}>
                {content}
            </button>
        );
    }
);

Button.displayName = "Button";

export default Button;
