import classnames from "classnames";
import * as React from "react";
import ErrorIcon from "./../../../icons/monochrome/24px/error-filled.svg";
import * as styles from "./TextInput.css";

export interface TextInputProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "disabled" | "prefix"> {
    label?: string | React.ReactNode;
    leadIcon?: React.ReactNode;
    trailIcon?: React.ReactNode;

    prefix?: React.ReactNode;
    suffix?: React.ReactNode;

    supportiveText?: React.ReactNode;

    // states
    disabled?: boolean;
    error?: boolean;
}

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
    (
        { leadIcon, trailIcon, error, disabled, suffix, prefix, supportiveText, ...inputProps },
        ref
    ) => {
        const classes = classnames(styles.container, {
            [styles.disabled]: disabled,
            [styles.readonly]: !!inputProps.readOnly,
            [styles.hasError]: !!error,
            [styles.withLeadIcon]: !!leadIcon,
            [styles.withTrailIcon]: !!trailIcon || error
        });

        return (
            <div className={classes}>
                <div className={styles.textField}>
                    {leadIcon && <span className={styles.leadIcon}>{leadIcon}</span>}
                    {prefix && <span className={styles.prefix}>{prefix}</span>}
                    <input ref={ref} className={styles.input} disabled={disabled} {...inputProps} />
                    {suffix && <span className={styles.suffix}>{suffix}</span>}
                    {(trailIcon || error) && (
                        <span className={styles.trailIcon}>
                            {error ? <ErrorIcon /> : trailIcon}
                        </span>
                    )}
                </div>
                {supportiveText && <div className={styles.supportiveText}>{supportiveText}</div>}
            </div>
        );
    }
);

TextInput.displayName = "TextInput";

export default TextInput;
