import classNames from "classnames";
import * as React from "react";
import * as styles from "./Stack.css";

interface StandardPageProps {
    children?: React.ReactNode;
    orientation?: "column" | "row";
    space?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
    justify?: "start" | "end" | "space-between";
}

const spaces = {
    0: "",
    1: styles.space1x,
    2: styles.space2x,
    3: styles.space3x,
    4: styles.space4x,
    5: styles.space5x,
    6: styles.space6x,
    7: styles.space7x,
    8: styles.space8x
};

const Stack = ({
    children,
    orientation = "column",
    justify = "start",
    space = 0
}: StandardPageProps) => (
    <div
        className={classNames(styles.container, spaces[space], {
            [styles.column]: orientation === "column",
            [styles.justifyStart]: justify === "start",
            [styles.justifyEnd]: justify === "end",
            [styles.justifySpaceBetween]: justify === "space-between"
        })}
    >
        {children}
    </div>
);

export default Stack;
