// extracted by mini-css-extract-plugin
var _1 = "Title--large--IhZMm";
var _2 = "Title--medium--xT7P0";
var _3 = "Title--on-background--Bf4Q1";
var _4 = "Title--on-error--CPqWf";
var _5 = "Title--on-error-container--nm5XW";
var _6 = "Title--on-primary--qSOwn";
var _7 = "Title--on-primary-container--Bt2XM";
var _8 = "Title--on-secondary--Q78hq";
var _9 = "Title--on-secondary-container--TfRrQ";
var _a = "Title--on-surface--O4Tew";
var _b = "Title--on-surface-variant--xQueo";
var _c = "Title--on-tertiary--c6fLE";
var _d = "Title--on-tertiary-container--GGqyb";
var _e = "Title--small--dIRPw";
var _f = "Title--title--Rc2uK";
export { _1 as "large", _2 as "medium", _3 as "on-background", _4 as "on-error", _5 as "on-error-container", _6 as "on-primary", _7 as "on-primary-container", _8 as "on-secondary", _9 as "on-secondary-container", _a as "on-surface", _b as "on-surface-variant", _c as "on-tertiary", _d as "on-tertiary-container", _e as "small", _f as "title" }
