// extracted by mini-css-extract-plugin
var _1 = "Button--container--DdpNW";
var _2 = "Button--danger--xOBvw";
var _3 = "Button--disabled--yNlHG";
var _4 = "Button--filled--Qi_IG";
var _5 = "Button--icon--AYA7w";
var _6 = "Button--primary--IF_8N";
var _7 = "Button--secondary--mkJqe";
var _8 = "Button--tertiary--U47yA";
var _9 = "Button--text--r3SC6";
var _a = "Button--tonal--KueXE";
var _b = "Button--withIcon--Hj_Ax";
export { _1 as "container", _2 as "danger", _3 as "disabled", _4 as "filled", _5 as "icon", _6 as "primary", _7 as "secondary", _8 as "tertiary", _9 as "text", _a as "tonal", _b as "withIcon" }
