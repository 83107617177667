import classNames from "classnames";
import React from "react";
import * as styles from "./Title.css";

interface TitleProps {
    scale?: "small" | "medium" | "large";
    color?:
        | "on-primary"
        | "on-primary-container"
        | "on-secondary"
        | "on-secondary-container"
        | "on-tertiary"
        | "on-tertiary-container"
        | "on-background"
        | "on-error"
        | "on-error-container"
        | "on-surface"
        | "on-surface-variant";

    children?: React.ReactNode;
}

const Title = ({ children, scale, color }: TitleProps) => {
    return (
        <span
            className={classNames(
                styles.title,
                styles[scale || "medium"],
                styles[color || "on-surface"]
            )}
        >
            {children}
        </span>
    );
};

export default Title;
