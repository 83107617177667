// extracted by mini-css-extract-plugin
var _1 = "Label--inverse-on-surface--XelWr";
var _2 = "Label--label--n_xDX";
var _3 = "Label--large--WICBH";
var _4 = "Label--medium--NuAZm";
var _5 = "Label--on-background--i0azA";
var _6 = "Label--on-error--lfs8c";
var _7 = "Label--on-error-container--ec_6T";
var _8 = "Label--on-primary--awiDy";
var _9 = "Label--on-primary-container--tNOv3";
var _a = "Label--on-secondary--Kyi1K";
var _b = "Label--on-secondary-container--yZSt3";
var _c = "Label--on-surface--TDMuG";
var _d = "Label--on-surface-variant--Oijyl";
var _e = "Label--on-tertiary--TL4iz";
var _f = "Label--on-tertiary-container--HaE31";
var _10 = "Label--prominent--z7VwA";
var _11 = "Label--small--uerSj";
export { _1 as "inverse-on-surface", _2 as "label", _3 as "large", _4 as "medium", _5 as "on-background", _6 as "on-error", _7 as "on-error-container", _8 as "on-primary", _9 as "on-primary-container", _a as "on-secondary", _b as "on-secondary-container", _c as "on-surface", _d as "on-surface-variant", _e as "on-tertiary", _f as "on-tertiary-container", _10 as "prominent", _11 as "small" }
