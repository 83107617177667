// extracted by mini-css-extract-plugin
var _1 = "AuthenticationForm--container--CMB2Z";
var _2 = "AuthenticationForm--disclaimer--k2w2W";
var _3 = "AuthenticationForm--editEmail--U0BLl";
var _4 = "AuthenticationForm--forgotPw--NTu9p";
var _5 = "AuthenticationForm--formError--NWXDu";
var _6 = "AuthenticationForm--header--PPZst";
var _7 = "AuthenticationForm--input--HvWiZ";
var _8 = "AuthenticationForm--passwordContainer--hUwst";
var _9 = "AuthenticationForm--password__enabled--sOBZh";
var _a = "AuthenticationForm--signIn--mwg_t";
export { _1 as "container", _2 as "disclaimer", _3 as "editEmail", _4 as "forgotPw", _5 as "formError", _6 as "header", _7 as "input", _8 as "passwordContainer", _9 as "password__enabled", _a as "signIn" }
