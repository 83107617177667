import { useCallback, useMemo } from "react";
import useFetch from "./useFetch";

export interface ModelState {
    name: string;
    validationState: "Unvalidated" | "Invalid" | "Valid" | "Skipped";
    errors: Array<{ errorMessage: string }>;
    attemptedValue?: unknown;
}

const requestOptions: RequestInit & { url: string } = {
    url: "/Identity/Account/Login",
    method: "POST",
    cache: "no-cache",
    credentials: "include",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8"
    },
    redirect: "manual"
};

export default (returnUrl: string, xsrf: string) => {
    const requestOptionsAndToken = useMemo(
        () => ({
            ...requestOptions,
            headers: { ...requestOptions.headers, RequestVerificationToken: xsrf }
        }),
        [xsrf]
    );

    const handleSuccess = useCallback((data: unknown) => {
        if (
            typeof data === "object" &&
            "redirectUrl" in data &&
            typeof data.redirectUrl === "string"
        ) {
            window.location.replace(data.redirectUrl);
        }
    }, []);

    const { state, sendRequest, errors } = useFetch(requestOptionsAndToken, handleSuccess);

    return {
        commit: (email: string, password: string) => {
            sendRequest({
                body: JSON.stringify({
                    Email: email,
                    Password: password,
                    ReturnUrl: returnUrl
                })
            });
        },

        state,
        errors
    };
};
