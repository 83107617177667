import { useEffect, useRef } from "react";

const usePrevious = function usePrevious<T = unknown>(value: T): T {
    const ref = useRef(null);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};

export default usePrevious;
