// extracted by mini-css-extract-plugin
var _1 = "Stack--column--jFo08";
var _2 = "Stack--container--MavLl";
var _3 = "Stack--justifyEnd--glAUP";
var _4 = "Stack--justifySpaceBetween--Hx8l8";
var _5 = "Stack--justifyStart--wyOWZ";
var _6 = "Stack--space1x--ZvBp5";
var _7 = "Stack--space2x--ZxnOb";
var _8 = "Stack--space3x--oYKiP";
var _9 = "Stack--space4x--xFGpk";
var _a = "Stack--space5x--bUiXr";
var _b = "Stack--space6x--IiQQP";
var _c = "Stack--space7x--n6yxW";
var _d = "Stack--space8x--w7jqw";
export { _1 as "column", _2 as "container", _3 as "justifyEnd", _4 as "justifySpaceBetween", _5 as "justifyStart", _6 as "space1x", _7 as "space2x", _8 as "space3x", _9 as "space4x", _a as "space5x", _b as "space6x", _c as "space7x", _d as "space8x" }
