import Title from "@ds/elements/text/Title";
import * as React from "react";
import * as styles from "./PageBox.css";

export interface PageBoxProps {
    title: React.ReactNode;
    description?: React.ReactNode;
    children: React.ReactNode;
    footer?: React.ReactNode;
}

const Pagebox = ({ description, title, children, footer }: PageBoxProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.layout}>
                <div className={styles.title}>
                    <Title scale="large" color="on-tertiary-container">
                        {title}
                    </Title>
                </div>
                {description && <div className={styles.description}>{description}</div>}
                <div className={styles.content}>{children}</div>
                <div className={styles.footer}>{footer}</div>
            </div>
        </div>
    );
};

export default Pagebox;
